
.judge-page-design {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  .student-box {
    width: 216px;
    border-right: 1px solid #d9d9d9;
    .student-item {
      height: 40px;
      line-height: 40px;
      background: #fafafa;
      color: #666;
      padding: 0 12px;
      margin-bottom: 10px;
      border-radius: 4px;
    }
    .student-active {
      background: #1cb064;
      color: #fff;
    }
  }
  .view-box {
    flex: 1;
  }
  .score-box {
    width: 304px;
    border-left: 1px solid #d9d9d9;
    color: #727272;
    font-size: 16px;
    .line {
      width: 1px;
      height: 12px;
      background: #d9d9d9;
    }
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
