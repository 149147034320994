
.new-decorator-full-screen {
  box-sizing: border-box;
  height: 100%;
  .page-view {
    height: 100%;
    background: #fff;
    .dz-logo {
      max-height: 120px;
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        margin-left: 20%;
        height: 83.5%;
        position: absolute;
        z-index: 1;
      }
    }
    .classify-box {
      padding-left: 20%;
      line-height: 30px;
      background: #fff;
      > span {
        margin-right: 12px;
        font-size: 12px;
        color: #666;
      }
    }
    .banner-box {
      width: 100%;
      height: 0;
      padding-bottom: 32.8125%;
      background: #fafafa;
      position: relative;
      .carousel-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        :deep(.el-carousel__container) {
          height: 100%;
        }
        :deep(.el-carousel__item) {
          display: flex;
          align-items: center;
          justify-content: center;
          .item {
            max-width: 100%;
            max-height: 100%;
          }
        }
        :deep(.el-carousel__button) {
          width: 10px;
          height: 10px;
          border: none;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.2);
        }
        :deep(.is-active .el-carousel__button) {
          background: #1cb064;
        }
      }
    }
    .sort-box {
      padding-left: 20%;
      margin-top: 7px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: #fff;
      .sort-item {
        min-width: 40px;
        height: 22px;
        font-size: 12px;
        border: 1px solid #e8e8e8;
        margin: 0 7px 7px 0;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .goods-box {
      padding: 0 20% 40px 20%;
      background: #fff;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .goods-item {
        color: #000;
        margin: 0 20px 20px 0;
        cursor: pointer;
      }
      .singe-line {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
  :deep(.el-carousel__button) {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  :deep(.is-active .el-carousel__button) {
    background: #1cb064;
  }
}
